import.meta.glob([
    '../img/**',
    '../video/**',
]);

// Load general bootstrap
import './bootstrap';

// Extract store
store = window.store;

// Load localization
/*import messagesEn from '../lang/en/vue.json';
import messagesNl from '../lang/nl/vue.json';

const i18n = new VueI18n({
    locale: document.querySelector('html').getAttribute('lang'),
    messages: {
        en: messagesEn,
        nl: messagesNl,
    },
    silentTranslationWarn: true,
});
import VueI18n from 'vue-i18n';*/
function $t(message, replacements = {}) {
    return message.replace(/\{(\w+)\}/g, (match, key) => {
        return replacements[key] || match;
    });
}

function $tc(message, count = 1, replacements = {}) {
    // Split the message by '|' to handle different plural forms
    const messages = message.split('|');
    let selectedMessage;

    if (count === 1 && messages.length > 0) {
        // Use the first variant for singular
        selectedMessage = messages[0];
    } else if (messages.length > 1) {
        // Use the second variant for plural
        selectedMessage = messages[1];
    } else {
        // Fallback if no proper message is found
        selectedMessage = message;
    }

    // Replace '{count}' and any other placeholders
    return selectedMessage.replace(/\{(\w+)\}/g, (match, key) => {
        // Replace with the value from replacements or use 'count' if 'key' is 'count'
        return key === 'count' ? count : (replacements[key] || match);
    });
}

Vue.prototype.$t = $t;
Vue.prototype.$tc = $tc;

// Add sentry
import * as Sentry from '@sentry/vue';
import {captureConsoleIntegration, httpClientIntegration} from "@sentry/integrations";

window.Sentry = Sentry;

if (import.meta.env.VITE_SENTRY_DSN_PUBLIC)
    if (!window.disableSentry)
        Sentry.init({
            Vue,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                captureConsoleIntegration(),
                httpClientIntegration(),
                new Sentry.BrowserTracing(),
                new Sentry.Replay({
                    blockAllMedia: false,
                    maskAllText: false,
                    networkDetailAllowUrls: [
                        window.location.origin,
                    ],
                    networkRequestHeaders: [
                        '*',
                    ],
                    networkResponseHeaders: [
                        '*',
                    ],
                }),
            ],
            // This option is required for capturing headers and cookies.
            sendDefaultPii: true,
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

// Create Vue app
const app = new Vue({
    // i18n,
    el: '#app',
    data: function () {
        return {
            data: {},
            globalData: {},
            date: [],
            imagesIndex: null,
        };
    },
    methods: {
        unsetImagesIndex: function () {
            this.imagesIndex = null;
        },
    },

    store,
});